import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_DEVELOP_URL;

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});
axios.interceptors.response.use(response => {
  return response;
}, error => {
 if (error.response && error.response.status === 401) {
    localStorage.removeItem("token");
    if(localStorage.getItem("refresh")){
      location.reload();
    }
    if(error.response.responseUR && error.response.responseURL.includes("/user/refresh")) {
      localStorage.removeItem("refresh");
    }
 }
 return Promise.reject(error);
});
export default axios;
