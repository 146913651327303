<template>
  <router-view />
</template>

<script>
import axios from "@/api/axios";
import LogRocket from "logrocket";

export default {
  methods: {
    clearCache() {
      axios
        .get("https://moscow.tdcsk.com/api/cache-clear", {
          headers: {
            Origin: process.env.VUE_APP_DEVELOP_URL,
            "Access-Control-Allow-Origin": process.env.VUE_APP_DEVELOP_URL,
          },
        })
        .then(() => {
          this.$toast.success("Кэш успешно очищен!");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    LogRocket.init("qiul8x/adnin");
  },
  created() {
    console.log(this.$router)
    //document.title = "Развиваем мир, развивая технологии";

    // Подгружаем все регионы
    this.$store.dispatch("getAllRegions");
  },
};
</script>
